import React from "react";
import Hero from "../Layout/Hero";

export default function TermsAndPrivacy() {
  return (
    <main>
      <Hero
        pictureKey="termsAndPrivacy"
        altKey="termsAndPrivacy"
        overlayKey="termsAndPrivacy"
      />
    </main>
  );
}
