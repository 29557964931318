import React from 'react'
import Map from "../Contact/Map";

export default function Information() {
  return (
<div className="flex flex-col md:flex-row w-full md:w-auto">
    <section className="bg-gray-800 text-white p-8 order-first md:order-first">
    <div className="max-w-4xl mx-auto text-center">
      <h2 className="text-3xl font-bold mb-6">Contactez-moi</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Email */}
        <div className="flex flex-col items-center">
          <i className="fas fa-envelope text-2xl mb-2"></i>
          <p>d.s.r.91@free.fr</p>
        </div>

        {/* Numéro de téléphone */}
        <div className="flex flex-col items-center">
          <i className="fas fa-phone text-2xl mb-2"></i>
          <p>+33 6 00 00 00 00</p>
        </div>

        {/* Numéro de fixe */}
        <div className="flex flex-col items-center">
          <i className="fas fa-phone-alt text-2xl mb-2"></i>
          <p>+33 1 00 00 00 00</p>
        </div>
      </div>
    </div>
  </section>

    <Map />
</div>
  )
}
