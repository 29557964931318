import React from "react";
import Hero from "../Layout/Hero";
import Reviews from "../CustomerReviews/Reviews";

export default function CustomerReviews() {
  return (
    <section>
      <Hero
        pictureKey="customerReviews"
        altKey="customerReviews"
        overlayKey="customerReviews"
      />
      <Reviews />
    </section>
  );
}
