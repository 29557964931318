import React from "react";

import contactImage from "../../assets/pictures/dsr-hero-Contact.jpg";
import customerReviewsImage from "../../assets/pictures/dsr-hero-CustomerReviews.jpg";
import homeImage from "../../assets/pictures/dsr-hero-Home.jpg";
import renovationImage from "../../assets/pictures/dsr-hero-Renovation.jpg";
import termsAndPrivacy from "../../assets/pictures/dsr-hero-TermsAndPrivacy.jpg";

const heroData = {
  images: {
    home: homeImage,
    renovation: renovationImage,
    contact: contactImage,
    customerReviews: customerReviewsImage,
    termsAndPrivacy: termsAndPrivacy,
  },
  altTexts: {
    home: "Image d'accueil",
    renovation: "Image de rénovation",
    contact: "Image de contact",
    customerReviews: "Image de commentaires clients",
    termsAndPrivacy: "Image des conditions d'utilisation et de confidentialité",
  },
  overlayTexts: {
    home: "Entreprise DSR",
    renovation: "Rénovation",
    contact: "Contact",
    customerReviews: "Avis-Clients",
    termsAndPrivacy: "Politique de protection des données et de la vie privée",
  },
};

export default function Hero({ pictureKey }) {
  const image = heroData.images[pictureKey];
  const altText = heroData.altTexts[pictureKey];
  const overlayText = heroData.overlayTexts[pictureKey];

  return (
    <section className="relative">
      <img
        src={image}
        className="w-full h-[60vh] max-lg:h-[45vh] object-cover shadow-4xl brightness-50 "
        alt={altText}
      />
      <h1 className="text-clamp-lg text-center font-black text-white absolute top-50 left-50 flex items-center justify-center transform -translate-x-1/2 -translate-y-1/2">
        {overlayText}
      </h1>
    </section>
  );
}
