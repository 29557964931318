import React from "react";

import Form from "../Contact/Form";
import Information from "../Contact/Information";

import Hero from "../Layout/Hero";
export default function Contact() {
  return (
    <main className="">
      <Hero pictureKey="contact" altKey="contact" overlayKey="contact" />
      <Information />
      <Form />
    </main>
  );
}
