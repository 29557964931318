import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonies = [
  {
    text: "Flowbite is just awesome. It contains tons of predesigned components and pages starting from the login screen to a complex dashboard. A perfect choice for your next SaaS application.",
    author: "Micheal Gough",
    position: "CEO at Google",
  },
  {
    text: "Flowbite is just awesome. It contains tons of predesigned components and pages starting from the login screen to a complex dashboard. A perfect choice for your next SaaS application.",
    author: "Micheal Gough",
    position: "CEO at Google",
  },
  {
    text: "Flowbite is just awesome. It contains tons of predesigned components and pages starting from the login screen to a complex dashboard. A perfect choice for your next SaaS application.",
    author: "Micheal Gough",
    position: "CEO at Google",
  },
  {
    text: "Flowbite is just awesome. It contains tons of predesigned components and pages starting from the login screen to a complex dashboard. A perfect choice for your next SaaS application.",
    author: "Micheal Gough",
    position: "CEO at Google",
  },
  // Add more testimonies as needed
];

export default function Reviews() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Add autoplay
    autoplaySpeed: 3000, // Set autoplay speed in milliseconds (e.g., 5000 for 5 seconds)
    arrows: false,
    
  };

  return (
    <section className="bg-gray-900">
      <div className="max-w-screen-xl py-8 mx-auto text-center lg:py-16 lg:px-6">
        <Slider {...sliderSettings}>
          {testimonies.map((testimonial, index) => (
            <div key={index} className="max-w-screen-md mx-auto bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
              <figure         data-aos="fade-up">
                <svg className="h-12 mx-auto mb-3 text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
                </svg>
                <blockquote>
                  <p className="text-2xl font-medium text-white">{testimonial.text}</p>
                </blockquote>
                <figcaption className="flex items-center justify-center mt-6 space-x-3">
                  <div className="flex items-center divide-x-2 divide-gray-700">
                    <div className="pr-3 font-medium text-white">{testimonial.author}</div>
                    <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">{testimonial.position}</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
