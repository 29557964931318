
import "aos/dist/aos.css";

import ContentHome from "../Home/Content";
import FeatureHome from "../Home/Feature";
import Hero from "../Layout/Hero";


export default function Home() {
  

  return (
    <main>
      <Hero pictureKey="home" altKey="home" overlayKey="home" />
      <ContentHome />
      <FeatureHome />

    </main>
  );
}
