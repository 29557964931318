import React from "react";

const themes = [
  {
    icon: (
      <svg
        className="w-5 h-5  lg:w-6 lg:h-6 text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Icône pour la Maçonnerie */}
        {/* ... */}
      </svg>
    ),
    title: "Maçonnerie",
    description:
      "Des services de maçonnerie de qualité pour construire et rénover des espaces solides et durables.",
  },
  {
    icon: (
      <svg
        className="w-5 h-5 lg:w-6 lg:h-6text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Icône pour l'Isolation */}
        {/* ... */}
      </svg>
    ),
    title: "Isolation",
    description:
      "Assurez une isolation thermique efficace pour un confort optimal dans vos espaces.",
  },
  {
    icon: (
      <svg
        className="w-5 h-5 lg:w-6 lg:h-6 text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Icône pour la Peinture */}
        {/* ... */}
      </svg>
    ),
    title: "Peinture",
    description:
      "Des services de peinture artistique pour embellir et personnaliser chaque espace selon vos préférences.",
  },
  {
    icon: (
      <svg
        className="w-5 h-5  lg:w-6 lg:h-6 text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Icône pour les Petit-Travaux */}
        {/* ... */}
      </svg>
    ),
    title: "Petit-Travaux",
    description:
      "Solutions sur mesure pour tous vos petits travaux de rénovation, réparation et amélioration.",
  },
];

export default function FeatureHome() {
  return (
    <section className="bg-white dark:bg-gray-800">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="max-w-screen-md mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white "
          data-aos="fade-right">
            Conçu pour les besoins spécifiques de votre entreprise DSR
          </h2>
          <p className="text-gray-500 dark:text-gray-300 sm:text-xl">
            Chez DSR, nous mettons l'accent sur les services de maçonnerie,
            isolation, peinture et petits travaux. Notre technologie innovante
            et notre approche personnalisée sont conçues pour débloquer une
            valeur à long terme et stimuler la croissance économique.
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 text-gray-500 dark:text-gray-300">
          {themes.map((theme, index) => (
            <FeatureItem key={index} {...theme} />
          ))}
        </div>
      </div>
    </section>
  );
}

const FeatureItem = ({ icon, title, description }) => (
  <div data-aos="fade-up">
    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 bg-primary-900 text-ellipsis">
      {icon}
    </div>
    <h3 className="mb-2 text-xl font-bold">{title}</h3>
    <p className="text-gray-500 dark:text-gray-400">{description}</p>
  </div>
);
