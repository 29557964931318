import React from "react";

export default function Map() {
  return (
    <iframe
      title="Google Maps"
      className="inset-0 w-full h-full"
      loading="lazy"
      allowFullScreen
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCUBnEFE3nVDUK1Jy03DZ9kpq0hBTpgEtA&q=48.68381975375403, 2.187436263924811&zoom=17"
    ></iframe>
  );
}
