import "aos/dist/aos.css";
import React, { useState } from "react";

// Constantes pour les classes CSS réutilisées
const inputClass = `
  appearance-none block w-full bg-gray-200 dark:bg-gray-800
  text-stone-800 border dark:border-none rounded py-3 px-4 leading-tight
  focus:outline-none focus:bg-white dark:focus:bg-gray-700 dark:text-white
`;

const labelClass = `
  block uppercase tracking-wide text-stone-800 dark:text-white
  text-clamp-form font-bold mb-2
`;

const buttonClass = `
  mt-6 bg-gray-400 hover:bg-gray-950 text-white dark:bg-gray-800
  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110
  mb-6 text-center
`;

// Messages d'erreur et de succès
const ERROR_MESSAGES = {
  requiredFields: "Veuillez remplir tous les champs requis.",
  emailFormat: "Veuillez entrer une adresse e-mail valide.",
  phoneFormat: "Veuillez entrer un numéro de téléphone valide.",
  submissionError:
    "Une erreur s'est produite lors de l'envoi du formulaire. Veuillez réessayer.",
  waitTimeError: "Vous devez attendre 24 heures entre chaque soumission.",
};

const SUCCESS_MESSAGES = {
  submissionSuccess: "Votre message a été envoyé avec succès.",
};

// Composant InputField
const InputField = ({ id, value, type, label, onChange, required }) => (
  <div className="w-full px-2 sm:px-4 md:px-8 lg:px-16 " key={id}>
    <label className={`${labelClass}`} htmlFor={id}>
      {label}
    </label>
    {type === "textarea" ? (
      <textarea
        className={`${inputClass} h-48 resize-none`}
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        required={required}
      />
    ) : (
      <input
        className={inputClass}
        id={id}
        name={id}
        type={type}
        value={type === "file" ? undefined : value}
        onChange={onChange}
        required={required}
      />
    )}
  </div>
);

// Définition des champs du formulaire
const inputs = [
  { id: "name", type: "text", label: "Nom", required: true },
  { id: "address", type: "text", label: "Adresse", required: true },
  { id: "city", type: "text", label: "Ville", required: true },
  { id: "phone", type: "tel", label: "Numéro de téléphone", required: true },
  { id: "email", type: "email", label: "Email", required: true },
  { id: "message", type: "textarea", label: "Message", required: true },
  {
    id: "file",
    type: "file",
    label: "Télécharger des photos",
    required: false,
  },
];

// Inputs cachés pour la soumission du formulaire
const hiddenInputs = [
  { name: "access_key", value: "88fc9a80-439c-4556-9208-446f5236cc25" },
  { name: "subject", value: "Message Entreprise DSR" },
  { name: "redirect", value: "https://entreprise-dsr.me/contact" },
  { name: "from_name", value: "Contact Entreprise DSR" },
];

function Form() {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
    file: null,
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Validation du formulaire
  const validateForm = (formState) => {
    for (let field in formState) {
      if (
        formState[field] === "" &&
        inputs.find((input) => input.id === field && input.required)
      ) {
        setErrorMessage(ERROR_MESSAGES.requiredFields);
        setSuccessMessage("");
        return false;
      }
    }

    // Vérification du format de l'adresse e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formState.email)) {
      setErrorMessage("Veuillez entrer une adresse e-mail valide.");
      setSuccessMessage("");
      return false;
    }

    // Vérification du format du numéro de téléphone
    const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (!phoneRegex.test(formState.phone)) {
      setErrorMessage("Veuillez entrer un numéro de téléphone valide.");
      setSuccessMessage("");
      return false;
    }

    setErrorMessage("");
    return true;
  };

  // Gestion des changements dans les champs du formulaire
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  // Soumission du formulaire
  const handleSubmit = (event) => {
    event.preventDefault();

    // Réinitialiser le message de succès
    setSuccessMessage("");

    const formData = {
      ...formState,
      ...hiddenInputs.reduce((obj, item) => {
        obj[item.name] = item.value;
        return obj;
      }, {}),
    };

    if (validateForm(formState)) {
      let lastSubmissionTime = localStorage.getItem("lastSubmissionTime");
      let currentTime = new Date().getTime();

      if (
        lastSubmissionTime &&
        currentTime - lastSubmissionTime < 24 * 60 * 60 * 1000
      ) {
        setErrorMessage(ERROR_MESSAGES.waitTimeError);
        return;
      }

      fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            localStorage.setItem("lastSubmissionTime", currentTime);
            setSuccessMessage(SUCCESS_MESSAGES.submissionSuccess);
            setErrorMessage("");
          } else {
            setErrorMessage(ERROR_MESSAGES.submissionError);
          }
        })
        .catch(() => {
          setErrorMessage(ERROR_MESSAGES.submissionError);
        });
    }
  };
  return (
    <section className="bg-white dark:bg-gray-900 text-stone-800 dark:text-white">
      <h4 className="font-black text-2xl sm:text-3xl text-center md:text-4xl lg:text-5xl xl:text-6xl flex justify-center items-center py-12 maxsm:text-clamp-form">
        Envoyez-nous un message !
      </h4>
      <form
        className="w-full h-auto flex flex-col justify-center items-center rounded-lg shadow-md "
        action="https://api.web3forms.com/submit"
        method="POST"
        data-aos="fade-up"
        onSubmit={handleSubmit}
      >
        {hiddenInputs.map(({ name, value }) => (
          <input key={name} type="hidden" name={name} value={value} />
        ))}
        {inputs.map(({ id, type, label, required }) => (
          <InputField
            key={id}
            id={id}
            type={type}
            label={label}
            onChange={handleInputChange}
            required={required}
          />
        ))}
        {successMessage && (
          <p className="text-green-600 text-center text-clamp-form">
            {successMessage}
          </p>
        )}
        {errorMessage && (
          <p className="text-rose-600 text-center text-clamp-form">
            {errorMessage}
          </p>
        )}
        <button className={buttonClass} type="submit">
          Envoyer !
        </button>
      </form>
    </section>
  );
}

export default Form;
