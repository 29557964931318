import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/pictures/dsr-logo.png";

const NavLink = ({ to, label, onClick }) => (
  <li>
    <Link
      to={to}
      className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black dark:after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center font-medium"
      onClick={onClick}
    >
      {label}
    </Link>
  </li>
);

const MobileNavLink = ({ to, label, onClick }) => (
  <li className="flex justify-center w-full py-4">
    <Link
      to={to}
      className="relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black dark:after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
      onClick={onClick}
    >
      {label}
    </Link>
  </li>
);

const navLinks = [
  { to: "/", label: "Accueil" },
  { to: "/renovation", label: "Rénovation" },
  { to: "/contact", label: "Contact" },
  { to: "/avis-clients", label: "Avis clients" },
];

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="absolute w-full z-20 ">
      {/* Menu NavBar */}
      <nav className="bg-white dark:bg-gray-900 text-stone-600 dark:text-white font-medium shadow-[0_4px_40px_0px_rgba(0,0,0,0.6)] flex justify-between py-3 px-5 lg:px-8 m-6 min-h-[5rem] rounded-lg maxsm:hidden ">
        <Link to="/">
          <img src={logo} className="h-16 maxsm:hidden" alt="Logo de DSR" />
        </Link>
        {/* Menu NavLink */}
        <ul className="hidden md:flex items-center space-x-5">
          {navLinks.map((link) => (
            <NavLink
              key={link.to}
              to={link.to}
              label={link.label}
              onClick={closeMenu}
            />
          ))}
        </ul>

        {/* Menu MobileNavLink */}
        <button
          className="space-y-1 group md:hidden "
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bg-black dark:bg-white w-6 h-1"></div>
          <div className="bg-black dark:bg-white mt-1 w-6 h-1"></div>
          <div className="bg-black dark:bg-white mt-1 w-6 h-1"></div>
        </button>

        <ul
          className={`bg-[#FFFFFF] dark:bg-gray-900 w-screen pb-10 absolute z-30 transform top-0 transition-all duration-500 ease-in-out ${
            isOpen ? "translate-y-0 " : "-translate-y-full "
          } right-0 flex flex-col space-y-3 justify-end shadow-[0_4px_40px_0px_rgba(0,0,0,0.1)]`}
        >
          <button
            className="px-10 py-8 relative ml-auto"
            onClick={() => setIsOpen(false)}
          >
            <div className="bg-black dark:bg-white w-6 h-1 rotate-45 absolute"></div>
            <div className="bg-black dark:bg-white w-6 h-1 -rotate-45 absolute"></div>
          </button>

          {navLinks.map((link) => (
            <MobileNavLink
              key={link.to}
              to={link.to}
              label={link.label}
              onClick={() => setIsOpen(false)}
            />
          ))}
        </ul>
      </nav>
    </header>
  );
}
