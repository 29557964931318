import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/pictures/dsr-logo.png";

const footerLinks = [
  { to: "/info", text: "À-propos" },
  { to: "/info", text: "Politique de Vie Privée" },
  { to: "/info", text: "Licences" },
  { to: "/contact", text: "Contact" },
];

const logoSrc = logo;
const companyName = "DSR";
const currentYear = new Date().getFullYear();

const FooterLink = ({ link }) => (
  <li className="">
    <Link to={link.to} className="hover:underline md:me-6">
      {link.text}
    </Link>
  </li>
);

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-900 shadow">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <Link
            to="/"
            className="flex items-center mb-4 md:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img src={logoSrc} className="h-8" alt={`Logo ${companyName} `} />
            <h1 className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white maxsm:hidden">
              {companyName}
            </h1>
          </Link>
          <ul
            className="flex flex-wrap items-center text-center justify-center mb-6 font-medium dark:text-gray-400 flex-col md:flex-row md:mb-0"

          >
            {footerLinks.map((link, index) => (
              <FooterLink key={index} link={link} />
            ))}
          </ul>
        </div>
        <hr className="my-6 border-gray-700 sm:mx-auto lg:my-8" />

        <span className="block  dark:text-gray-400 text-center sm:text-base lg:text-lg">
          © {currentYear}{" "}
          <Link to="/" className="hover:underline">
            {companyName}
          </Link>™
          . Tous droits réservés.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
