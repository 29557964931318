import React from "react";
import Hero from "../Layout/Hero";
import Partners from "../Renovation/Partners";

export default function Renovation() {
  return (
    <main>
      <Hero
        pictureKey="renovation"
        altKey="renovation"
        overlayKey="renovation"
      />
      <Partners />
    </main>
  );
}

