import React, { useEffect } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import AOS from "aos";
import NavBar from "./Layout/NavBar";
import Home from "./Pages/Home";
import Renovation from "./Pages/Renovation";
import Contact from "./Pages/Contact";
import CustomerReviews from "./Pages/CustomerReviews";
import Footer from "./Layout/Footer";
import TermsAndPrivacy from "./Pages/TermsAndPrivacy";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <React.Fragment>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/renovation" element={<Renovation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/avis-clients" element={<CustomerReviews />} />
        <Route path="/info" element={<TermsAndPrivacy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
