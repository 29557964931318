import PropTypes from "prop-types";
import React from "react";

// Composant principal
const ContentHome = () => {
  const textBlockContent = {
    title: "Entreprise de rénovation à Orsay",
    paragraphs: [
      "Chez DSR, nous redonnons vie à chaque espace avec excellence. Experts en rénovation et artisans du bâtiment, nous allions stratégie, design et savoir-faire pour créer des espaces exceptionnels.",
      "Que vous recherchiez une rénovation complète ou des solutions créatives, notre équipe rapide et efficace réalise vos projets à la perfection.",
    ],
  };

  const imageBlockContent = {
    images: [
      require("../../assets/pictures/dsr-Content-Home-1.jpg"),
      require("../../assets/pictures/dsr-Content-Home-2.jpg"),
    ],
  };

  return (
    <section className="bg-white dark:bg-gray-900 text-gray-500 dark:text-gray-300">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6 ">
        <TextBlock {...textBlockContent} />
        <ImageBlock {...imageBlockContent} />
      </div>
    </section>
  );
};

// Nouveaux composants réutilisables
const TextBlock = ({ title, paragraphs }) => (
  <div className="font-light sm:text-lg">
    <h2
      className="mb-4 text-4xl tracking-tight font-extrabold"
      data-aos="fade-right"
    >
      {title}
    </h2>
    {paragraphs.map((paragraph, index) => (
      <p key={index} className="mb-4">
        {paragraph}
      </p>
    ))}
  </div>
);

const ImageBlock = ({ images }) => (
  <div className="grid grid-cols-2 gap-4 mt-8" data-aos="fade-up">
    {images.map((src, index) => (
      <img
        key={index}
        className={`w-full ${index === 1 ? "mt-4 lg:mt-10" : ""} rounded-lg`}
        src={src}
        alt={`office content ${index + 1}`}
      />
    ))}
  </div>
);

// Validation des propriétés avec PropTypes
TextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ImageBlock.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ContentHome;
